import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PublicIcon from '@mui/icons-material/Public';
import StackedLineChartOutlinedIcon from '@mui/icons-material/StackedLineChartOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
    BBCINCORP_ID,
    BBCINCORP_SG_ID,
    VISA2ASIA_ID,
    GLEADS_ID,
    TASK_READ,
    CUSTOMER_READ,
    USER_CUSTOMER_READ,
    ORDER_READ,
    ORDER_RESTORE,
    COMPANY_READ,
    SERVICE_READ,
    PACKAGE_READ,
    BANKING_TOOL_READ,
    USER_READ,
    ROLE_READ,
    AGENT_READ,
    CONFIG_READ,
    API_READ,
    SUPPORT_CASE_READ,
    GLEADS_VIEW,
    VISA2ASIA_VIEW,
    BBCSG_VIEW,
    ARRIVALHUB_VIEW,
    COMPANY_MEMBER_READ,
    ARRIVALHUB_ID,
    TASK_RESTORE,
    SYSLOG_READ
} from 'constants/index';

const iconStyles = { color: 'rgba(255, 255, 255, 0.7)' };

export const menu = [
    {
        icon: <StackedLineChartOutlinedIcon />,
        title: 'Dashboard',
        permissions: ['STATISTIC_MEMBER', 'STATISTIC_ADMIN', 'STATISTIC_LEADER'],
        to: '/dashboard'
    },
    {
        icon: <PublicIcon sx={iconStyles} />,
        title: 'BBCincorp.com',
        permissions: [
            /* Newer*/
            TASK_READ,
            CUSTOMER_READ,
            ORDER_READ,
            COMPANY_READ,
            PACKAGE_READ,
            SERVICE_READ
        ],
        items: [
            {
                title: 'Task',
                to: '/bbcincorp/task/all?status=UNFINISHED&is_finished=false',
                permissions: [TASK_READ]
            },
            {
                title: 'Customers',
                to: `/bbcincorp/customer/all?website_id=${BBCINCORP_ID}`,
                permissions: [CUSTOMER_READ]
            },
            {
                title: 'Orders',
                to: `/bbcincorp/order?website_id=[${BBCINCORP_ID}]`,
                permissions: [ORDER_READ]
            },
            {
                title: 'Invoices',
                to: `/bbcincorp/invoices`,
                permissions: [ORDER_READ]
            },
            {
                title: 'Company',
                to: '/bbcincorp/company/all',
                permissions: [COMPANY_READ]
            },
            // {
            //     title: 'Company Member',
            //     to: '/bbcincorp/company-member',
            //     permissions: [COMPANY_MEMBER_READ]
            // },
            {
                title: 'Service',
                to: `/bbcincorp/service?website_id=${BBCINCORP_ID}`,
                permissions: [SERVICE_READ]
            },
            {
                title: 'Service type',
                to: '/bbcincorp/mservice-type',
                permissions: [SERVICE_READ]
            },
            {
                title: 'Package',
                to: `/bbcincorp/package?website_id=${BBCINCORP_ID}`,
                permissions: [PACKAGE_READ]
            },
            {
                title: 'Package Type',
                to: '/bbcincorp/package-type',
                permissions: [PACKAGE_READ]
            },
            {
                icon: <FiberManualRecordIcon sx={{ ...iconStyles, fontSize: 12 }} />,
                title: 'Tool',
                permissions: [BANKING_TOOL_READ],
                items: [
                    {
                        title: 'Banking Tool',
                        to: '/bbcincorp/banking-tool',
                        permissions: [BANKING_TOOL_READ]
                    }
                ]
            },
            {
                icon: <FiberManualRecordIcon sx={{ ...iconStyles, fontSize: 12 }} />,
                title: 'Contact Center',
                permissions: [TASK_READ],
                items: [
                    {
                        title: 'Whatsapp',
                        to: '/bbcincorp/whatsapp',
                        permissions: [TASK_READ],
                    }
                    // {
                    //     title: 'Tawk.to',
                    //     to: '/bbcincorp/package-type',
                    //     permissions: [TASK_READ]
                    // },
                ]
            }
        ]
    },
    {
        icon: <PublicIcon sx={iconStyles} />,
        title: 'BBCincorp.sg',
        permissions: [BBCSG_VIEW],
        items: [
            {
                title: 'Customers',
                to: `/bbcincorp-sg/customer/all?website_id=${BBCINCORP_SG_ID}`,
                permissions: [CUSTOMER_READ]
            },
            {
                title: 'Orders',
                to: `/bbcincorp-sg/order?website_id=[${BBCINCORP_SG_ID}]`,
                permissions: [ORDER_READ]
            },
            {
                title: 'Service',
                to: `/bbcincorp-sg/service?website_id=${BBCINCORP_SG_ID}`,
                permissions: [SERVICE_READ]
            },
            {
                title: 'Service type',
                to: '/bbcincorp-sg/mservice-type',
                permissions: [SERVICE_READ]
            },
            {
                title: 'Package',
                to: `/bbcincorp-sg/package?website_id=${BBCINCORP_SG_ID}`,
                permissions: [PACKAGE_READ]
            }
        ]
    },
    {
        icon: <PublicIcon sx={iconStyles} />,
        title: 'Visa2asia.com',
        permissions: [VISA2ASIA_VIEW],
        items: [
            {
                title: 'Customers',
                to: `/visa2asia/customer/all?website_id=${VISA2ASIA_ID}`,
                permissions: [CUSTOMER_READ]
            },
            {
                title: 'Orders',
                to: `/visa2asia/order?website_id=[${VISA2ASIA_ID}]`,
                permissions: [ORDER_READ]
            },
            {
                title: 'Service',
                to: `/visa2asia/service?website_id=${VISA2ASIA_ID}`,
                permissions: [SERVICE_READ]
            },
            {
                title: 'Service type',
                to: '/visa2asia/mservice-type',
                permissions: [SERVICE_READ]
            }
        ]
    },
    {
        icon: <PublicIcon sx={iconStyles} />,
        title: 'Gleads',
        permissions: [GLEADS_VIEW],
        items: [
            {
                title: 'Customers',
                to: `/gleads/customer/all?website_id=${GLEADS_ID}`,
                permissions: [CUSTOMER_READ]
            }
            // {
            //     title: 'Orders',
            //     to: `/gleads/order?website_id=[${GLEADS_ID}]`,
            //     permissions: [ORDER_READ]
            // },
            // {
            //     title: 'Service',
            //     to: `/gleads/service?website_id=${GLEADS_ID}`,
            //     permissions: [SERVICE_READ]
            // },
            // {
            //     title: 'Service type',
            //     to: '/gleads/mservice-type',
            //     permissions: [SERVICE_READ]
            // }
        ]
    },
    {
        icon: <PublicIcon sx={iconStyles} />,
        title: 'ArrivalHub',
        permissions: [ARRIVALHUB_VIEW],
        items: [
            {
                title: 'Customers',
                to: `/arrival-hub/customer/all?website_id=${ARRIVALHUB_ID}`,
                permissions: [CUSTOMER_READ]
            }
            // {
            //     title: 'Orders',
            //     to: `/arrival-hub/order?website_id=[${ARRIVALHUB_ID}]`,
            //     permissions: [ORDER_READ]
            // },
            // {
            //     title: 'Service',
            //     to: `/arrival-hub/service?website_id=${ARRIVALHUB_ID}`,
            //     permissions: [SERVICE_READ]
            // },
            // {
            //     title: 'Service type',
            //     to: '/arrival-hub/mservice-type',
            //     permissions: [SERVICE_READ]
            // }
        ]
    },
    {
        icon: <PeopleIcon sx={iconStyles} />,
        title: 'User Management',
        permissions: [USER_READ, USER_CUSTOMER_READ, ROLE_READ, CONFIG_READ, AGENT_READ],
        items: [
            {
                title: 'Employee Users Account',
                to: '/user-management/employee-user-account',
                permissions: [USER_READ]
            },
            {
                title: 'Customer Users Account',
                to: '/user-management/customer-user-account',
                permissions: [USER_CUSTOMER_READ]
            },
            {
                title: 'Roles Control',
                to: '/user-management/roles',
                permissions: [ROLE_READ]
            },
            {
                title: 'Limit',
                to: '/user-management/limit',
                permissions: [ROLE_READ]
            },
            {
                title: 'Limit Type',
                to: '/user-management/limit-type',
                permissions: [ROLE_READ]
            },
            {
                title: 'Process Step',
                to: '/user-management/process-step',
                permissions: [ROLE_READ]
            },
            {
                title: 'Agent',
                to: '/user-management/agent',
                permissions: [AGENT_READ]
            },
            {
                title: 'Agency',
                to: '/user-management/agency',
                permissions: [AGENT_READ]
            },
            {
                title: 'Permission',
                to: '/user-management/permission',
                permissions: [ROLE_READ]
            },
            {
                title: 'Permission Group',
                to: '/user-management/group-permission',
                permissions: [ROLE_READ]
            }
        ]
    },
    {
        icon: <SettingsIcon sx={iconStyles} />,
        title: 'Config',
        permissions: [CONFIG_READ, API_READ, ORDER_RESTORE, TASK_RESTORE, SYSLOG_READ],
        items: [
            {
                title: 'Api',
                to: '/config/api',
                permissions: [API_READ]
            },
            {
                title: 'Api Group',
                to: '/config/group-api',
                permissions: [API_READ]
            },
            {
                title: 'Syslog',
                to: '/config/syslog',
                permissions: [SYSLOG_READ]
            },
            {
                title: 'Syslog Report',
                to: '/config/syslog-report',
                permissions: [SYSLOG_READ]
            },
            {
                title: 'Syslog URL',
                to: '/config/syslog-url',
                permissions: [SYSLOG_READ]
            },
            {
                title: 'Currency',
                to: '/config/currency',
                permissions: [CONFIG_READ]
            },
            {
                title: 'Files',
                to: '/config/file',
                permissions: [CONFIG_READ]
            },
            {
                title: 'Contact Form',
                to: '/config/contact-form',
                permissions: [CONFIG_READ]
            },
            {
                title: 'Holiday',
                to: '/config/holiday',
                permissions: [CONFIG_READ]
            },
            {
                title: 'Bank',
                to: '/config/bank',
                permissions: [CONFIG_READ]
            },
            {
                title: 'Restore Task',
                to: '/config/restore-task',
                permissions: [TASK_RESTORE]
            },
            {
                title: 'Restore Order',
                to: '/config/restore-order',
                permissions: [ORDER_RESTORE]
            },
            {
                title: 'MailChimp Tag',
                to: '/config/mail-chimp-tag',
                permissions: [CONFIG_READ]
            },
            {
                title: 'Websites',
                to: '/config/websites',
                permissions: [CONFIG_READ]
            }
        ]
    },
    {
        icon: <SupportAgentIcon sx={iconStyles} />,
        title: 'Support',
        permissions: [SUPPORT_CASE_READ],
        items: [
            {
                title: 'Support Case',
                to: '/support/support-case/all',
                permissions: [SUPPORT_CASE_READ]
            }
        ]
    }
];
