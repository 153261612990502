import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import AdminLayout from './layout/admin/Admin';
import ShowWithPermissionCheck from './components/ShowWithPermissionCheck';
import {
    AGENT_READ,
    API_READ,
    BANK_READ,
    BANKING_TOOL_READ,
    BBCINCORP_ID,
    COMPANY_READ,
    CONFIG_READ,
    CUSTOMER_READ,
    GLEADS_ID,
    ORDER_READ,
    ORDER_RESTORE,
    PACKAGE_READ,
    ROLE_READ,
    SERVICE_READ,
    SUPPORT_CASE_READ,
    SYSLOG_READ,
    TASK_READ,
    USER_READ,
    VISA2ASIA_ID,
    COMPANY_MEMBER_READ,
    USER_CUSTOMER_READ
} from 'constants/index';
import { SERVICE_TYPE_EDIT } from './features/service-type/constants';
import { SERVICE_EDIT } from './features/service/constants';
import { PACKAGE_EDIT } from './features/package/constants';
import { CUSTOMER_EDIT } from './features/customer/constants';
import RequireAuth from './app/components/RequireAuth';
import { useSelector } from 'react-redux';
import { ORDER_EDIT } from 'features/order/constants';

const ErrorFeature = React.lazy(() => import('./features/error'));
const AuthFeature = React.lazy(() => import('./features/auth'));
const CurrencyFeature = React.lazy(() => import('./features/currency'));
const FileFeature = React.lazy(() => import('./features/file'));
const ContactFormFeature = React.lazy(() => import('./features/contact-form'));
const SyslogFeature = React.lazy(() => import('./features/syslog'));
const AgentFeature = React.lazy(() => import('./features/agent'));
const HolidayFeature = React.lazy(() => import('./features/holiday'));
const AgencyFeature = React.lazy(() => import('./features/agency'));
const SupportCaseFeature = React.lazy(() => import('./features/support-case'));
const BankFeature = React.lazy(() => import('./features/bank'));
const RestoreOrderFeature = React.lazy(() => import('./features/restore-order'));
const RestoreTaskFeature = React.lazy(() => import('./features/restore-task'));
const ProfileFeature = React.lazy(() => import('./features/personal'));
const MailChimpTagFeature = React.lazy(() => import('./features/mail-chimp-tag'));
const WebsiteFeature = React.lazy(() => import('./features/website'));
const PermissionFeature = React.lazy(() => import('./features/permission'));
const PermissionGroupFeature = React.lazy(() => import('./features/permission-group'));
const ServiceFeature = React.lazy(() => import('./features/service'));
const ServiceTypeFeature = React.lazy(() => import('./features/service-type'));
const PackageTypeFeature = React.lazy(() => import('./features/package-type'));
const PackageFeature = React.lazy(() => import('./features/package'));
const CustomerUserFeature = React.lazy(() => import('./features/customer-user-account'));
const EmployeeUserFeature = React.lazy(() => import('./features/employee-users-account'));
const OrderFeature = React.lazy(() => import('./features/order'));
const CompanyFeature = React.lazy(() => import('./features/company'));
const CompanyMemberFeature = React.lazy(() => import('./features/company-member'));
const TaskFeature = React.lazy(() => import('./features/task'));
const CustomerFeature = React.lazy(() => import('./features/customer'));
const SyslogReportFeature = React.lazy(() => import('./features/syslog-report'));
const SyslogUrlFeature = React.lazy(() => import('./features/syslog-url'));
const FileViewerFeature = React.lazy(() => import('./features/file-viewer'));
const BankingTool = React.lazy(() => import('./features/banking-tool'));
const ApiFeature = React.lazy(() => import('./features/api'));
const GroupApiFeature = React.lazy(() => import('./features/group-api'));
const DashboardFeature = React.lazy(() => import('./features/dashboard'));
const LimitFeature = React.lazy(() => import('./features/limit'));
const LimitTypeFeature = React.lazy(() => import('./features/limit-type'));
const RolesControlFeature = React.lazy(() => import('./features/roles-control'));
const ProcessStepFeature = React.lazy(() => import('./features/process-step'));
const WhatsAppFeature = React.lazy(() => import('./features/whatsapp'));
const InvoicesFeature = React.lazy(() => import('./features/invoices'));

function AppRouting(props) {
    const { hasNoAdminLayout } = useSelector((state) => state.auth);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <RequireAuth>{!hasNoAdminLayout ? <AdminLayout /> : <Outlet />}</RequireAuth>
                }>
                <Route
                    path="dashboard/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[
                                'STATISTIC_MEMBER',
                                'STATISTIC_ADMIN',
                                'STATISTIC_LEADER'
                            ]}
                            forRouting={true}>
                            <DashboardFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/api/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[API_READ]}
                            forRouting={true}>
                            <ApiFeature />
                        </ShowWithPermissionCheck>
                    }
                />
                <Route
                    path="config/group-api/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[API_READ]}
                            forRouting={true}>
                            <GroupApiFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/syslog/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SYSLOG_READ]}
                            forRouting={true}>
                            <SyslogFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/syslog-url/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SYSLOG_READ]}
                            forRouting={true}>
                            <SyslogUrlFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/syslog-report/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SYSLOG_READ]}
                            forRouting={true}>
                            <SyslogReportFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/currency/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CONFIG_READ]}
                            forRouting={true}>
                            <CurrencyFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/file/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CONFIG_READ]}
                            forRouting={true}>
                            <FileFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/contact-form/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CONFIG_READ]}
                            forRouting={true}>
                            <ContactFormFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/holiday/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CONFIG_READ]}
                            forRouting={true}>
                            <HolidayFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/bank/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[BANK_READ]}
                            forRouting={true}>
                            <BankFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/restore-task/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={['TASK_RESTORE']}
                            forRouting={true}>
                            <RestoreTaskFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/restore-order/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ORDER_RESTORE]}
                            forRouting={true}>
                            <RestoreOrderFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/mail-chimp-tag/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CONFIG_READ]}
                            forRouting={true}>
                            <MailChimpTagFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="config/websites/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CONFIG_READ]}
                            forRouting={true}>
                            <WebsiteFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/agent/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[AGENT_READ]}
                            forRouting={true}>
                            <AgentFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/agency/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[AGENT_READ]}
                            forRouting={true}>
                            <AgencyFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/roles/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ROLE_READ]}
                            forRouting={true}>
                            <RolesControlFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/limit/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ROLE_READ]}
                            forRouting={true}>
                            <LimitFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/limit-type/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ROLE_READ]}
                            forRouting={true}>
                            <LimitTypeFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/process-step/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ROLE_READ]}
                            forRouting={true}>
                            <ProcessStepFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/permission/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ROLE_READ]}
                            forRouting={true}>
                            <PermissionFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/roles/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={['ROLE_READ']}
                            forRouting={true}>
                            <RolesControlFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/group-permission/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ROLE_READ]}
                            forRouting={true}>
                            <PermissionGroupFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/customer-user-account/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[USER_CUSTOMER_READ]}
                            forRouting={true}>
                            <CustomerUserFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="user-management/employee-user-account/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[USER_READ]}
                            forRouting={true}>
                            <EmployeeUserFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                {/* START - bbcincorp */}
                <Route
                    path="bbcincorp/mservice-type/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SERVICE_READ]}
                            forRouting={true}>
                            <ServiceTypeFeature
                                websiteId={BBCINCORP_ID}
                                defaultValues={SERVICE_TYPE_EDIT.BBCINCORP}
                            />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp/service/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SERVICE_READ]}
                            forRouting={true}>
                            <ServiceFeature defaultValues={SERVICE_EDIT.BBCINCORP} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp/package-type/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[PACKAGE_READ]}
                            forRouting={true}>
                            <PackageTypeFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp/banking-tool/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[BANKING_TOOL_READ]}
                            forRouting={true}>
                            <BankingTool />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp/package/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[PACKAGE_READ]}
                            forRouting={true}>
                            <PackageFeature defaultValues={PACKAGE_EDIT.BBCINCORP} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp/order/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ORDER_READ]}
                            forRouting={true}>
                            <OrderFeature defaultValues={ORDER_EDIT.BBCINCORP} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp/invoices/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[TASK_READ]}
                            forRouting={true}>
                            <InvoicesFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp/company/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[COMPANY_READ]}
                            forRouting={true}>
                            <CompanyFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                {/* <Route
                    path="bbcincorp/company-member/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[COMPANY_MEMBER_READ]}
                            forRouting={true}>
                            <CompanyMemberFeature />
                        </ShowWithPermissionCheck>
                    }
                /> */}

                <Route
                    path="bbcincorp/customer/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CUSTOMER_READ]}
                            forRouting={true}>
                            <CustomerFeature defaultValues={CUSTOMER_EDIT.BBCINCORP} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp/task/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[TASK_READ]}
                            forRouting={true}>
                            <TaskFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp/whatsapp/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[TASK_READ]}
                            forRouting={true}>
                            <WhatsAppFeature />
                        </ShowWithPermissionCheck>
                    }
                />

                {/* END - bbcincorp */}

                {/* END - bbcincorp.sg */}
                <Route
                    path="bbcincorp-sg/customer/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CUSTOMER_READ]}
                            forRouting={true}>
                            <CustomerFeature defaultValues={CUSTOMER_EDIT.BBCINCORP_SG} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp-sg/order/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ORDER_READ]}
                            forRouting={true}>
                            <OrderFeature defaultValues={ORDER_EDIT.BBCINCORP_SG} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp-sg/service/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SERVICE_READ]}
                            forRouting={true}>
                            <ServiceFeature defaultValues={SERVICE_EDIT.BBCINCORP_SG} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp-sg/mservice-type/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SERVICE_READ]}
                            forRouting={true}>
                            <ServiceTypeFeature
                                websiteId={BBCINCORP_ID}
                                defaultValues={SERVICE_TYPE_EDIT.BBCINCORP_SG}
                            />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="bbcincorp-sg/package/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[PACKAGE_READ]}
                            forRouting={true}>
                            <PackageFeature defaultValues={PACKAGE_EDIT.BBCINCORP_SG} />
                        </ShowWithPermissionCheck>
                    }
                />

                {/* END - bbcincorp.sg */}

                {/* START - Visa2asia */}
                <Route
                    path="visa2asia/customer/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CUSTOMER_READ]}
                            forRouting={true}>
                            <CustomerFeature defaultValues={CUSTOMER_EDIT.VISA2ASIA} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="visa2asia/order/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ORDER_READ]}
                            forRouting={true}>
                            <OrderFeature defaultValues={ORDER_EDIT.VISA2ASIA} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="visa2asia/service/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SERVICE_READ]}
                            forRouting={true}>
                            <ServiceFeature defaultValues={SERVICE_EDIT.VISA2ASIA} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="visa2asia/mservice-type/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SERVICE_READ]}
                            forRouting={true}>
                            <ServiceTypeFeature
                                websiteId={VISA2ASIA_ID}
                                defaultValues={SERVICE_TYPE_EDIT.VISA2ASIA}
                            />
                        </ShowWithPermissionCheck>
                    }
                />
                {/* END - Visa2asia */}

                {/* START - gleads */}
                <Route
                    path="gleads/customer/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CUSTOMER_READ]}
                            forRouting={true}>
                            <CustomerFeature defaultValues={CUSTOMER_EDIT.GLEADS} />
                        </ShowWithPermissionCheck>
                    }
                />

                {/* <Route
                    path="gleads/order/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ORDER_READ]}
                            forRouting={true}>
                            <OrderFeature defaultValues={ORDER_EDIT.GLEADS} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="gleads/service/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SERVICE_READ]}
                            forRouting={true}>
                            <ServiceFeature defaultValues={SERVICE_EDIT.GLEADS} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="gleads/mservice-type/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SERVICE_READ]}
                            forRouting={true}>
                            <ServiceTypeFeature
                                websiteId={GLEADS_ID}
                                defaultValues={SERVICE_TYPE_EDIT.GLEADS}
                            />
                        </ShowWithPermissionCheck>
                    }
                /> */}
                {/* END - gleads */}

                {/* START - arrival-hub */}
                <Route
                    path="arrival-hub/customer/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[CUSTOMER_READ]}
                            forRouting={true}>
                            <CustomerFeature defaultValues={CUSTOMER_EDIT.ARRIVALHUB} />
                        </ShowWithPermissionCheck>
                    }
                />

                {/* <Route
                    path="arrival-hub/order/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[ORDER_READ]}
                            forRouting={true}>
                            <OrderFeature defaultValues={ORDER_EDIT.ARRIVALHUB} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="arrival-hub/service/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SERVICE_READ]}
                            forRouting={true}>
                            <ServiceFeature defaultValues={SERVICE_EDIT.ARRIVALHUB} />
                        </ShowWithPermissionCheck>
                    }
                />

                <Route
                    path="arrival-hub/mservice-type/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SERVICE_READ]}
                            forRouting={true}>
                            <ServiceTypeFeature
                                websiteId={GLEADS_ID}
                                defaultValues={SERVICE_TYPE_EDIT.ARRIVALHUB}
                            />
                        </ShowWithPermissionCheck>
                    }
                /> */}
                {/* END - arrival-hub */}

                <Route
                    path="support/support-case/*"
                    element={
                        <ShowWithPermissionCheck
                            permissions={[SUPPORT_CASE_READ]}
                            forRouting={true}>
                            <SupportCaseFeature />
                        </ShowWithPermissionCheck>
                    }
                />
                <Route
                    path="profile/*"
                    element={<ProfileFeature />}
                />
                <Route
                    index
                    element={<Navigate to={'/profile'} />}
                />
            </Route>
            <Route
                path="auth/*"
                element={<AuthFeature />}
            />
            <Route
                path="error/*"
                element={<ErrorFeature />}
            />
            <Route
                path="file-viewer/*"
                element={
                    <RequireAuth>
                        <FileViewerFeature />
                    </RequireAuth>
                }
            />
            <Route
                path="*"
                element={
                    <Navigate
                        to={'/error/404'}
                        replace
                    />
                }
            />
        </Routes>
    );
}

export default AppRouting;
