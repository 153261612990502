import { scanFileApi } from 'api';
import moment from 'moment';
import { toast } from 'react-toastify';

export const IMG_EXTENSION = ['.jpg', '.jpeg', '.png'];
export const DOCS_EXTENSION = ['.pdf', '.doc', '.docx'];
export const VIDEO_EXTENSION = ['.mov', '.mp4', '.avi', '.mkv', '.wmv'];

export const ALL_ACCEPT_EXTENSION = [...IMG_EXTENSION, ...DOCS_EXTENSION, ...VIDEO_EXTENSION];
export const INVOICES_ACCEPT_EXTENSION = ['.jpg', '.png', '.pdf'];

export const isFilesAccept = (files, extensions = ALL_ACCEPT_EXTENSION) => {
    if (!files?.length) {
        return true;
    }

    for (let i = 0; i < files.length; i++) {
        const fileExt = '.' + files[i].name.split('.').pop().toLowerCase();
        if (!extensions.includes(fileExt)) {
            return false;
        }

        if (fileExt === '.mkv' && window?.navigator?.platform?.toUpperCase()?.indexOf('MAC') >= 0) {
            return false;
        }
    }

    return true;
};

export const scanFile = async (files) => {
    try {
        const expiredToken = localStorage.getItem('scan_file_exp')
            ? moment().isAfter(localStorage.getItem('scan_file_exp'))
            : null;

        let accessToken, expiresIn;
        let isValid = true;

        if (
            expiredToken ||
            !localStorage.getItem('scan_file_token') ||
            !localStorage.getItem('scan_file_exp')
        ) {
            const res = await scanFileApi.getToken();
            accessToken = res?.accessToken;
            expiresIn = res?.expiresIn;
            localStorage.setItem('scan_file_token', accessToken);
            localStorage.setItem('scan_file_exp', moment().add(expiresIn - 60, 'seconds'));
        } else {
            accessToken = localStorage.getItem('scan_file_token');
        }

        if (accessToken) {
            for (let i = 0; i < files.length; i++) {
                const response = await scanFileApi.scanFile(files[i], accessToken);
                if (response?.result === 'Clean') {
                    files[i].isValid = true;
                } else {
                    files[i].isValid = false;
                    isValid = false;
                }
            }
            return {
                scannedFiles: files,
                isValid
            };
        }
    } catch (error) {
        toast.error('Fail to scan files!');
        console.log(error);
        return {
            failToScan: true
        };
    }
};
