import React, { Suspense, useEffect, useRef, useState } from 'react';
import {
    Box,
    Drawer as MuiDrawer,
    AppBar as MuiAppBar,
    CssBaseline,
    Divider,
    IconButton,
    List,
    ListItemIcon,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    Button,
    Badge
} from '@mui/material';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Logout } from '@mui/icons-material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import './Admin.scss';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthData } from 'features/auth/store/authSlice';
import {
    fetchCheckNotification,
    fetchMessageNotification,
    fetchNewsNotification,
    fetchOthersNotification,
    fetchReadAllMessageNotification,
    fetchReadAllNewsNotification,
    fetchReadAllOthersNotification,
    fetchWhatsappNotification
} from 'features/notification/store/notificationThunk';
import { fetchPersonal } from 'features/personal/store/personalThunk';
import Notification from 'features/notification/components/Notification/Notification';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MessageIcon from '@mui/icons-material/Message';
import TaskIcon from '@mui/icons-material/Task';
import {
    updateMessageNotifications,
    updateNewsNotifications,
    updateOthersNotifications,
    updatePageMessageNotifications,
    updatePageNewsNotifications,
    updatePageOthersNotifications,
    clearNotification
} from 'features/notification/store/notificationSlice';
import { menu } from './menu';
import { MMenuItem } from './components/MMenuItem';
import { Loading } from './components/Loading';
import { updatePersonal } from 'features/personal/store/personalSlice';
import Note from 'features/note/components/Note';
import { notificationApi } from 'api/notificationApi';
import io from 'socket.io-client';
import { Howl } from 'howler';
import { getFullName, setPageTitle } from 'helper';
import { fetchGetAccessTokenDetail } from 'features/auth/store/authThunk';
import { eventEmitter } from 'helper/utils';
import { updateWhatsappWebsocket } from 'features/whatsapp/store/whatsAppSlice';
import { whatsappApi } from 'api';
import { usePermission } from 'hook';
import { ALLOWED_ROLES_USE_WHATSAPP } from 'constants';

const drawerWidth = 265;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        zIndex: theme.zIndex.drawer - 1,
        width: `calc(100% - ${drawerWidth}px + 70px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    }),
    [theme.breakpoints.down('sm')]: {
        width: `calc(100% - ${drawerWidth}px + 284px)`
    }
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#1a1a27',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, hover }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme)
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme)
        }),
        ...(hover && {
            ...hoverDrawer(theme),
            '& .MuiDrawer-paper': hoverDrawer(theme)
        })
    })
);

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const hoverDrawer = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    })
});

function AdminLayout() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDrawer, setOpenDrawer] = React.useState(true);
    const [hoverDrawer, setHoverDrawer] = React.useState(false);
    const [isCloseCollapse, setIsCloseCollapse] = React.useState(false);
    const { personal } = useSelector((state) => state.personal);
    const { Roles } = useSelector((state) => state.auth);
    let { current: socketRef } = useRef(null);
    let { current: soundRef } = useRef(null);
    const soundWhatsappRef = useRef(null);
    let memoryUsageRef = useRef(null);
    const { hasRoles } = usePermission();
    const isShowWhatsapp = hasRoles(ALLOWED_ROLES_USE_WHATSAPP);

    const { access_token } = useSelector((state) => state.auth);
    const {
        loadingReadAllNewsNotification,
        unreadNewsNotificationMap,
        countUnreadNewsNotification,
        loadingNewsNotification,
        newsNotifications,
        pageNewsNotifications
    } = useSelector((state) => state.notification);
    const {
        loadingReadAllMessageNotification,
        unreadMessageNotificationMap,
        countUnreadMessageNotification,
        loadingMessageNotification,
        messageNotifications,
        pageMessageNotifications
    } = useSelector((state) => state.notification);
    const {
        loadingReadAllOthersNotification,
        unreadOthersNotificationMap,
        countUnreadOthersNotification,
        loadingOthersNotification,
        othersNotifications,
        pageOthersNotifications
    } = useSelector((state) => state.notification);
    const [showBubbleChat, setShowBubbleChat] = useState(false);
    const [unreadMessagesCountBubbleChat, setUnreadMessagesCountBubbleChat] = useState(0);
    const { countUnreadWhatsappNotification } = useSelector((state) => state.notification);

    const isMenuOpen = Boolean(anchorEl);

    /* Effect check memory usage */
    useEffect(() => {
        if (process.env.REACT_APP_PRODUCTION !== 'false') return;
        let intervalCheckMemory = setInterval(() => {
            const memory =
                'Memory used: ' +
                (window.performance.memory.usedJSHeapSize / 1024 / 1024).toFixed(3) +
                ' / ' +
                (window.performance.memory.totalJSHeapSize / 1024 / 1024).toFixed(3) +
                ' MB';
            memoryUsageRef.current.innerText = memory;
        }, 300);

        return () => {
            clearInterval(intervalCheckMemory);
        };
    }, []);

    useEffect(() => {
        socketRef = io(process.env.REACT_APP_URI_SOCKET_SUNBRIGHT, {
            transports: ['websocket', 'polling'],
            upgrade: false
        });

        socketRef.on('connect', () => {
            console.log('socket connected!');
            socketRef.emit('join', {
                authorization: access_token,
                deviceInfor: navigator.userAgent
            });
        });

        socketRef.on('received-notification', (data) => {
            switch (data.notification.notification_type_id) {
                case 1:
                    dispatch(updateNewsNotifications(data.notification));
                    break;
                case 2:
                    dispatch(updateMessageNotifications(data.notification));
                    break;
                case 3:
                    dispatch(updateOthersNotifications(data.notification));
                    break;
            }
            dispatch(fetchCheckNotification());
            soundRef.play();
        });

        socketRef.on('disconnect', () => {
            console.log('socket disconnected');
        });

        return () => {
            socketRef.disconnect();
        };
    }, []);

    useEffect(() => {
        soundRef = new Howl({
            src: ['/sound/anxious-586.mp3']
        });
        soundWhatsappRef.current = new Howl({
            src: ['/sound/whatsapp-notification.mp3']
        });
    }, []);

    useEffect(() => {
        if (!personal) {
            dispatch(fetchPersonal());
        }
        dispatch(fetchWhatsappNotification({}));
    }, []);

    useEffect(() => {
        dispatch(fetchCheckNotification());
    }, []);

    useEffect(() => {
        void handleGetPermissionsRoles();
    }, []);

    useEffect(() => {
        setPageTitle(
            countUnreadNewsNotification +
                countUnreadMessageNotification +
                countUnreadOthersNotification
        );
    }, [
        countUnreadNewsNotification,
        countUnreadMessageNotification,
        countUnreadOthersNotification
    ]);

    useEffect(() => {
        dispatch(fetchNewsNotification(pageNewsNotifications));
    }, [pageNewsNotifications]);

    useEffect(() => {
        dispatch(fetchMessageNotification(pageMessageNotifications));
    }, [pageMessageNotifications]);

    useEffect(() => {
        dispatch(fetchOthersNotification(pageOthersNotifications));
    }, [pageOthersNotifications]);

    const handleGetPermissionsRoles = async () => {
        const resultAction = await dispatch(fetchGetAccessTokenDetail(access_token));
        if (fetchGetAccessTokenDetail.fulfilled.match(resultAction)) {
        } else {
        }
    };

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 1200) {
                setOpenDrawer(false);
                setIsCloseCollapse(true);
            } else {
                setOpenDrawer(true);
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleToggleBubbleChat = async (conversationId, closeBubbleChat) => {
        try {
            if (closeBubbleChat) {
                localStorage.removeItem('conversation_id');
                setShowBubbleChat(false);
                return;
            }

            const { data } = await whatsappApi.getUnreadMessage({ ids: [conversationId] });
            const unreadMessagesCount = data?.[0]?.numberOfUnread || 0;

            setShowBubbleChat(true);
            setUnreadMessagesCountBubbleChat(unreadMessagesCount || 0);
            localStorage.setItem('conversation_id', conversationId);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        try {
            if (localStorage.getItem('conversation_id')) {
                handleToggleBubbleChat(localStorage.getItem('conversation_id'));
            }

            eventEmitter.addListener('toggleBubbleChat', handleToggleBubbleChat);

            return () => {
                eventEmitter.removeListener('toggleBubbleChat', handleToggleBubbleChat);
            };
        } catch (error) {
            console.log(error);
        }
    }, []);

    //Initial Whatsapp socket
    useEffect(() => {
        let socket;
        try {
            const startWebSocket = () => {
                socket = new WebSocket(process.env.REACT_APP_WHATSAPP_WEBSOCKET_URL);

                socket.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    if (data.event === 'RECEIVED') {
                        if (!location.pathname.includes('whatsapp')) {
                            soundWhatsappRef?.current?.play();
                        }
                        if (localStorage.getItem('conversation_id')) {
                            handleToggleBubbleChat(localStorage.getItem('conversation_id'));
                        }
                        dispatch(fetchWhatsappNotification({}));
                    }
                };

                socket.onclose = (e) => {
                    setTimeout(() => {
                        startWebSocket();
                    }, 1500);
                };

                socket.onerror = function (err) {
                    console.error('Socket encountered error: ', err.message, 'Closing socket');
                    socket.close();
                };

                dispatch(updateWhatsappWebsocket({ socket }));
            };

            isShowWhatsapp && startWebSocket();
        } catch (e) {
            console.log(e);
        }

        return () => socket?.close();
    }, []);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        navigate('/auth/login');
        dispatch(clearAuthData());
        dispatch(clearNotification());
        dispatch(updatePersonal(null));
        localStorage.removeItem('conversation_id');
    };

    const handleReadAllNewsNotification = async () => {
        const resultAction = await dispatch(fetchReadAllNewsNotification());
        if (!fetchReadAllNewsNotification.fulfilled.match(resultAction)) {
            return;
        }
        dispatch(fetchCheckNotification());
    };

    const handleReadAllMessageNotification = async () => {
        const resultAction = await dispatch(fetchReadAllMessageNotification());
        if (!fetchReadAllMessageNotification.fulfilled.match(resultAction)) {
            return;
        }
        dispatch(fetchCheckNotification());
    };

    const handleReadAllOthersNotification = async () => {
        const resultAction = await dispatch(fetchReadAllOthersNotification());
        if (!fetchReadAllOthersNotification.fulfilled.match(resultAction)) {
            return;
        }
        dispatch(fetchCheckNotification());
    };

    const handleReadNotification = async (id) => {
        try {
            await notificationApi.read(id);
            dispatch(fetchCheckNotification());
        } catch (e) {}
    };

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
        setIsCloseCollapse(false);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
        setHoverDrawer(false);
        setIsCloseCollapse(true);
    };

    const handleDrawerHover = () => {
        setHoverDrawer(true);
        setIsCloseCollapse(false);
    };

    const handleDrawerLeave = () => {
        if (!openDrawer) {
            setHoverDrawer(false);
            setIsCloseCollapse(true);
        }
        setHoverDrawer(false);
    };

    const menuId = 'user-top-bar-popover';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            open={isMenuOpen}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    width: '200px',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem>
                <Box sx={{ width: 1 }}>
                    <Typography
                        variant="inherit"
                        noWrap>
                        {personal?.Personal?.first_name + ' ' + personal?.Personal?.last_name}
                    </Typography>
                    <Typography
                        sx={{ fontSize: '0.8rem' }}
                        whiteSpace={'pre-wrap'}>
                        {Roles.join(', ')}
                    </Typography>
                </Box>
            </MenuItem>
            <Divider />
            <MenuItem>
                <NavLink to={'/profile'}>
                    <AccountCircle sx={{ mr: 2 }} /> My account
                </NavLink>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                open={openDrawer}
                position="fixed">
                <Toolbar sx={{ backgroundColor: 'white', color: '#000', paddingLeft: 0 }}>
                    <Box
                        sx={{
                            minHeight: 64,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#1a1a27',
                            width: 65,
                            marginLeft: -3
                        }}>
                        <IconButton onClick={handleDrawerOpen}>
                            <ChevronRightIcon style={{ color: 'white' }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isShowWhatsapp && (
                            <IconButton onClick={() => navigate('/bbcincorp/whatsapp')}>
                                <Badge
                                    color="error"
                                    badgeContent={countUnreadWhatsappNotification}>
                                    <img
                                        width={23}
                                        src="/images/whatsapp.png"
                                        style={{
                                            pointerEvents: 'none'
                                        }}
                                        alt="logo-whatsapp"
                                    />
                                </Badge>
                            </IconButton>
                        )}
                        <Notification
                            onScrollEnd={() =>
                                dispatch(updatePageNewsNotifications(pageNewsNotifications + 1))
                            }
                            loadingFetchData={
                                loadingNewsNotification || loadingReadAllNewsNotification
                            }
                            data={newsNotifications}
                            label={'News'}
                            icon={<FiberNewIcon color="primary" />}
                            countUnread={countUnreadNewsNotification}
                            unreadMap={unreadNewsNotificationMap}
                            iconMessage={<FeedbackIcon style={{ color: '#1a1a27' }} />}
                            onReadAll={handleReadAllNewsNotification}
                            onRead={handleReadNotification}
                        />
                        <Notification
                            onScrollEnd={() =>
                                dispatch(
                                    updatePageMessageNotifications(pageMessageNotifications + 1)
                                )
                            }
                            loadingFetchData={
                                loadingMessageNotification || loadingReadAllMessageNotification
                            }
                            data={messageNotifications}
                            label={'Message'}
                            icon={<MessageIcon color="primary" />}
                            countUnread={countUnreadMessageNotification}
                            unreadMap={unreadMessageNotificationMap}
                            iconMessage={<FeedbackIcon style={{ color: '#1a1a27' }} />}
                            onReadAll={handleReadAllMessageNotification}
                            onRead={handleReadNotification}
                        />
                        <Notification
                            onScrollEnd={() =>
                                dispatch(updatePageOthersNotifications(pageOthersNotifications + 1))
                            }
                            loadingFetchData={
                                loadingOthersNotification || loadingReadAllOthersNotification
                            }
                            data={othersNotifications}
                            label={'Others'}
                            icon={<NotificationsIcon color="primary" />}
                            countUnread={countUnreadOthersNotification}
                            unreadMap={unreadOthersNotificationMap}
                            iconMessage={<TaskIcon style={{ color: '#1a1a27' }} />}
                            onReadAll={handleReadAllOthersNotification}
                            onRead={handleReadNotification}
                        />
                        <Button
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            sx={{
                                marginLeft: 2,
                                padding: '5px'
                            }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: 500 }}>
                                <Typography
                                    variant="inherit"
                                    noWrap
                                    sx={{
                                        marginRight: 0.5,
                                        fontSize: 16,
                                        color: '#b5b5c3'
                                    }}>
                                    Hi,
                                </Typography>
                                <Typography
                                    variant="inherit"
                                    noWrap
                                    sx={{
                                        fontSize: 16,
                                        color: '#7e8299'
                                    }}>
                                    {getFullName(personal?.Personal)}
                                </Typography>
                                {personal?.Personal?.image ? (
                                    <img
                                        src={personal?.Personal?.image}
                                        style={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                            marginLeft: 10
                                        }}
                                        alt="avatar"
                                    />
                                ) : (
                                    <AccountCircle color="disable" />
                                )}
                            </Box>
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMenu}
            <Drawer
                open={openDrawer}
                hover={hoverDrawer}
                variant="permanent"
                PaperProps={{
                    sx: {
                        backgroundColor: '#1e1e2d',
                        color: 'white'
                    }
                }}
                onMouseOver={handleDrawerHover}
                onMouseLeave={handleDrawerLeave}>
                <DrawerHeader>
                    <Box
                        sx={{
                            minHeight: 64,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <NavLink to={'/'}>
                            <img
                                src="/images/bbcincorp-logo-H60px.svg"
                                alt="BBCIncorp-Logo"
                                width={150}
                            />
                        </NavLink>
                        <IconButton
                            onClick={handleDrawerClose}
                            sx={{ marginLeft: 7 }}>
                            <ChevronLeftIcon style={{ color: 'white' }} />
                        </IconButton>
                    </Box>
                </DrawerHeader>
                <Box sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    <List>
                        {menu.map((item, key) => (
                            <MMenuItem
                                key={key}
                                item={item}
                                isCloseCollapse={isCloseCollapse}
                            />
                        ))}
                    </List>
                </Box>
            </Drawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, minWidth: 0 }}>
                <Toolbar />
                <Suspense fallback={<Loading />}>
                    <Outlet />
                </Suspense>
            </Box>
            {showBubbleChat && (
                <Box
                    position={'fixed'}
                    right={20}
                    bottom={60}
                    zIndex={1000}>
                    <Badge
                        badgeContent={Number(unreadMessagesCountBubbleChat)}
                        color="error"
                        sx={{
                            '.MuiBadge-badge': {
                                right: '12px',
                                top: '12px'
                            }
                        }}>
                        <IconButton
                            color={'primary'}
                            onClick={() => {
                                setShowBubbleChat(false);
                                navigate('/bbcincorp/whatsapp', {
                                    state: { isClickedBubbleChat: true }
                                });
                            }}
                            size={'large'}
                            sx={{ ml: 'auto' }}>
                            <img
                                width={30}
                                src="/images/whatsapp.png"
                                style={{
                                    pointerEvents: 'none'
                                }}
                                alt="logo-whatsapp"
                            />
                        </IconButton>
                    </Badge>
                </Box>
            )}
            <Note />
            {process.env.REACT_APP_PRODUCTION === 'false' && (
                <Box
                    position={'fixed'}
                    left={15}
                    bottom={15}
                    zIndex={10000}>
                    <Typography
                        fontSize={'0.8rem'}
                        ref={memoryUsageRef}
                    />
                </Box>
            )}
        </Box>
    );
}

export default AdminLayout;
